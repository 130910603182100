import axios from 'axios';
import TokenService from './TokenService';
import Util from './Util'

export default class BackendService {

    static getBackendURL() {
        if (window.location.href.includes("localhost") || window.location.href.includes("storm-dev")) {
            //console.log("dev")
            return "https://storm-dev.pactual.net/api/storm"
        }
        if (window.location.href.includes("storm-uat")) {
            //console.log("uat")
            return "https://storm-uat.pactual.net/api/storm"
        }
        if (window.location.href.includes("storm-new")) {
            //console.log("uat")
            return "https://storm-new.pactual.net/api/storm"
        }
        //console.log("prod")
        return "https://storm.pactual.net/api/storm"
    }

    static get(url, returnBlob, isLocal, port) {
        if (url.charAt(0) !== "/") {
            url = "/" + url
        }
        const config = {
            headers: {
            }
        }
        if (returnBlob) {
            config.responseType = 'blob'
        }
        if (window.location.href.includes("localhost")) {
            config.headers["S-Auth"] = TokenService.token
        }
        if (isLocal) {
            url = 'http://localhost:' + port + url
        } else {
            url = BackendService.getBackendURL() + url
        }
        return axios.get(url, config)
            .then(res => {
                res.data["status"] = res.status
                return res.data
            })
            .catch((error) => {
                error.response.data["status"] = error.response.status
                console.log(error);
                Util.loading = false;
                if (error.response.status === 403) {
                    if (window.location.host.includes("localhost")) {
                        return
                    }
                    localStorage.setItem("token", "null")
                    window.location = BackendService.getBackendURL() + "/sso/login?page=" + encodeURIComponent(window.location.hash)
                }
                return error.response.data
            });
    }

    static put(url, payload, returnBlob, isLocal, port) {
        if (url.charAt(0) !== "/") {
            url = "/" + url
        }
        const config = {
            headers: {
            }
        }
        if (returnBlob) {
            config.responseType = 'blob'
        }
        if (window.location.href.includes("localhost")) {
            config.headers["S-Auth"] = TokenService.token
        }
        if (isLocal) {
            url = 'http://localhost:' + port + url
        } else {
            url = BackendService.getBackendURL() + url
        }
        return axios.put(url, payload, config)
            .then(res => {
                res.data["status"] = res.status
                return res.data
            })
            .catch((error) => {
                error.response.data["status"] = error.response.status
                console.log(error);
                Util.loading = false;
                if (error.response.status === 403) {
                    if (window.location.host.includes("localhost")) {
                        return
                    }
                    localStorage.setItem("token", "null")
                    window.location = BackendService.getBackendURL() + "/sso/login?page=" + encodeURIComponent(window.location.hash)
                }
            });
    }

    static patch(url, payload, returnBlob, isLocal, port) {
        if (url.charAt(0) !== "/") {
            url = "/" + url
        }
        const config = {
            headers: {
            }
        }
        if (returnBlob) {
            config.responseType = 'blob'
        }
        if (window.location.href.includes("localhost")) {
            config.headers["S-Auth"] = TokenService.token
        }
        if (isLocal) {
            url = 'http://localhost:' + port + url
        } else {
            url = BackendService.getBackendURL() + url
        }
        return axios.patch(url, payload, config)
            .then(res => {
                res.data["status"] = res.status
                return res.data
            })
            .catch((error) => {
                error.response.data["status"] = error.response.status
                console.log(error);
                Util.loading = false;
                if (error.response.status === 403) {
                    if (window.location.host.includes("localhost")) {
                        return
                    }
                    localStorage.setItem("token", "null")
                    window.location = BackendService.getBackendURL() + "/sso/login?page=" + encodeURIComponent(window.location.hash)
                }
                return error.response.data
            });
    }

    static post(url, payload, returnBlob, isLocal, port) {

        if (url.charAt(0) !== "/") {
            url = "/" + url
        }
        const config = {
            headers: {
            }
        }
        if (returnBlob) {
            config.responseType = 'blob'
        }
        if (window.location.href.includes("localhost")) {
            config.headers["S-Auth"] = TokenService.token
        }
        if (isLocal) {
            url = 'http://localhost:' + port + url
        } else {
            url = BackendService.getBackendURL() + url
        }


        return axios.post(url, payload, config)
            .then(res => {
                res.data["status"] = res.status
                return res.data
            })
            .catch((error) => {
                error.response.data["status"] = error.response.status
                console.log(error);
                Util.loading = false;
                if (error.response.status === 403) {
                    if (window.location.host.includes("localhost")) {
                        return
                    }
                    localStorage.setItem("token", "null")
                    window.location = BackendService.getBackendURL() + "/sso/login?page=" + encodeURIComponent(window.location.hash)
                }
                return error.response.data
            });
    }

    static delete(url, payload, isLocal, port) {
        if (url.charAt(0) !== "/") {
            url = "/" + url
        }
        const config = {
            headers: {
            },
            data: payload
        }
        if (window.location.href.includes("localhost")) {
            config.headers["S-Auth"] = TokenService.token
        }
        if (isLocal) {
            url = 'http://localhost:' + port + url
        } else {
            url = BackendService.getBackendURL() + url
        }
        return axios.delete(url, config)
            .then(res => {
                res.data["status"] = res.status
                return res.data
            })
            .catch((error) => {
                error.response.data["status"] =  error.response.status
                console.log(error);
                Util.loading = false;
                if (error.response.status === 403) {
                    if (window.location.host.includes("localhost")) {
                        return
                    }
                    localStorage.setItem("token", "null")
                    window.location = BackendService.getBackendURL() + "/sso/login?page=" + encodeURIComponent(window.location.hash)
                }
            });
    }
}
