import { createRouter, createWebHashHistory } from 'vue-router';
import Util from './service/Util'
import UserService from './service/UserService';
import AnalyticsService from './service/AnalyticsService';

const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('./pages/Home.vue')
    },
    {
        path: '/new',
        name: 'newhome',
        component: () => import('./pages/NewHome.vue')
    },
    {
        path: '/incident/new',
        name: 'incident-new',
        component: () => import('./pages/IncidentNew.vue')
    },
    {
        path: '/incident/search',
        name: 'incident-search',
        component: () => import('./pages/IncidentViewModify.vue')
    },
    {
        path: '/issue/new',
        // name: 'incident-new',
        meta: {
            check: "any",
            // "*.storm.issue.*.create"
            roles: ["*.storm.issue.*.*"]
        },
        component: () => import('./pages/IssueNew.vue')
    },
    {
        path: '/issue/search',
        meta: {
            check: "any",
            // "*.storm.issue.*.view"
            roles: ["*.storm.issue.*.*"]
        },
        component: () => import('./pages/IssueViewModify.vue')
    },
    {
        path: '/maintenance',
        component: () => import('./pages/MaintencePage.vue')
    },
    // {
    //     path: '/incident/hashtag',
    //     name: 'hashtag-temp',
    //     component: () => import('./pages/hashtagTemp.vue')
    // },
    {
        path: '/incident/filter',
        name: 'incident-filter',
        component: () => import('./pages/IncidentFilter.vue')
    },
    {
        path: '/dro-file/upload',
        name: 'dro-file-upload',
        component: () => import('./pages/DROFileUpload.vue')
    },
    {
        path: '/dro-manager-files',
        name: 'dro-manager-files',
        component: () => import('./pages/DROManagerFiles.vue')
    },
    {
        path: '/dro-manager',
        name: 'dro-manager',
        component: () => import('./pages/DROAdmin.vue')
    },
    {
        path: '/incident/batch/insert',
        name: 'teste-caio',
        meta: {
            check: "any",
            roles: ["*.storm.incident.*.bulk-insert"]
        },
        component: () => import('./pages/IncidentBatchInsert.vue')
    },
    {
        path: '/postdog',
        name: 'postdog',
        component: () => import('./pages/Postdog.vue')
    },
    {
        path: '/dro-handbook',
        name: 'dro-handbook',
        component: () => import('./pages/DROHandbook.vue')
    },
    {
        path: '/tpm',
        name: 'tpm',
        component: () => import('./pages/TPM.vue')
    },
    {
        path: '/tpm-form',
        name: 'tpm-form',
        component: () => import('./pages/TPMForm.vue')
    },
    {
        path: '/formlayout',
        name: 'formlayout',
        component: () => import('./components/FormLayoutDemo.vue')
    },
    {
        path: '/input',
        name: 'input',
        component: () => import('./components/InputDemo.vue')
    },
    {
        path: '/floatlabel',
        name: 'floatlabel',
        component: () => import('./components/FloatLabelDemo.vue')
    },
    {
        path: '/invalidstate',
        name: 'invalidstate',
        component: () => import('./components/InvalidStateDemo.vue')
    },
    {
        path: '/table',
        name: 'table',
        component: () => import('./components/TableDemo.vue')
    },
    {
        path: '/list',
        name: 'list',
        component: () => import('./components/ListDemo.vue')
    },
    {
        path: '/tree',
        name: 'tree',
        component: () => import('./components/TreeDemo.vue')
    },
    {
        path: '/panel',
        name: 'panel',
        component: () => import('./components/PanelsDemo.vue')
    },
    {
        path: '/overlay',
        name: 'overlay',
        component: () => import('./components/OverlayDemo.vue')
    },
    {
        path: '/media',
        name: 'media',
        component: () => import('./components/MediaDemo.vue')
    },
    {
        path: '/menu',
        component: () => import('./components/MenuDemo.vue'),
        children: [
            {
                path: '',
                component: () => import('./components/menu/PersonalDemo.vue')
            },
            {
                path: '/menu/seat',
                component: () => import('./components/menu/SeatDemo.vue')
            },
            {
                path: '/menu/payment',
                component: () => import('./components/menu/PaymentDemo.vue')
            },
            {
                path: '/menu/confirmation',
                component: () => import('./components/menu/ConfirmationDemo.vue')
            },
        ],
    },
    {
        path: '/messages',
        name: 'messages',
        component: () => import('./components/MessagesDemo.vue')
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: () => import('./components/Dashboard.vue')
    },
    {
        path: '/file',
        name: 'file',
        component: () => import('./components/FileDemo.vue')
    },
    {
        path: '/chart',
        name: 'chart',
        component: () => import('./components/ChartDemo.vue')
    },
    {
        path: '/misc',
        name: 'misc',
        component: () => import('./components/MiscDemo.vue')
    },
    {
        path: '/crud',
        name: 'crud',
        component: () => import('./pages/CrudDemo.vue')
    },
    {
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue')
    },
    {
        path: '/empty',
        name: 'empty',
        component: () => import('./components/EmptyPage.vue')
    },
    {
        path: '/documentation',
        name: 'documentation',
        component: () => import('./components/Documentation.vue')
    },
    {
        path: '/blocks',
        name: 'blocks',
        component: () => import('./components/BlocksDemo.vue')
    },
    {
        path: '/icons',
        name: 'icons',
        component: () => import('./components/IconsDemo.vue')
    },
    {
        path: '/landing',
        name: 'landing',
        component: () => import('./pages/LandingDemo.vue')
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue')
    },
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
    },
    {
        path: '/bia',
        name: 'bia',
        meta: {
            check: "any",
            // *.storm.bia.*.view
            roles: ["*.storm.bia.*.*"]
        },
        component: () => import('./pages/BiaHome.vue')
    },
    {
        path: '/new-bia',
        name: 'new-bia',
        meta: {
            check: "all",
            // *.storm.bia.*.create.campaign
            // *.storm.bia.*.create-campaign 
            // *.storm.bia-campaign.create provavelmente essa
            roles: ["*.storm.bia.*.manage"]
        },
        component: () => import('./pages/BiaNewCampaign.vue')
    },
    {
        path: '/bia/campaign',
        name: 'bia-campaign',
        meta: {
            check: "any",
            // *.storm.bia.*.view
            roles: ["*.storm.bia.*.*"]
        },
        component: () => import('./pages/BiaCampaign.vue')
    },
    {
        path: '/bia/campaign/form',
        name: 'bia-form',
        meta: {
            check: "any",
            // *.storm.bia.*.view
            roles: ["*.storm.bia.*.*"]
        },
        component: () => import('./pages/BiaForm.vue')
    },
    {
        path: '/bia/search/form',
        name: 'bia-search',
        meta: {
            check: "any",
            roles: ["*.storm.bia.*.manage"]
        },
        component: () => import('./pages/BiaSearchForm.vue')
    },
    {
        path: '/incident/bdpo/summary',
        name: 'bdpo-summary',
        meta: {
            check: "any",
            roles: ["*.storm.bdpo.*.view"]
        },
        component: () => import('./pages/BdpoSummary.vue')
    },
    {
        path: '/manager/roles',
        name: 'manage-roles',
        meta: {
            check: "any",
            roles: ["*.storm.manage.*.*"]
        },
        component: () => import('./pages/ManageRoles.vue')
    },
    {
        path: '/forbidden',
        name: 'forbidden',
        component: () => import('./pages/Forbidden.vue')
    },
    {
        path: '/file-share',
        name: 'file-share',
        component: () => import('./pages/FileShare.vue')
    },
    {
        path: '/teste',
        component: () => import('./pages/teste.vue')
    }
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to) => {
    Util.refreshPage = false
    if (to.meta.roles != undefined && to.meta.roles.length > 0) {
        if (to.meta.check == undefined || to.meta.check == "any") {
            if (UserService.checkUserRoleAny(to.meta.roles) == false) {
                return { path: '/forbidden' };
            }
        }
        else if (to.meta.check == "all") {
            if (UserService.checkUserRoleAll(to.meta.roles) == false) {
                return { path: '/forbidden' };
            }
        }
    }
    if (UserService.user != null) {
        AnalyticsService.send(to.name, UserService.user)
    }
})

export default router;
